import _ from 'lodash';
import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { StatsFilter } from '../../../../../domain';
import { API } from '../../../../apis';

import { useQuery } from '@tanstack/react-query';
import { EdErrorHandler } from '../../../../widgets';

import styles from './VisitsPerAppVersionChart.module.scss'
import classNames from 'classnames';

const RADIAN = Math.PI / 180;

const COLORS = ['#002e67', '#009BC2', '#ee7d11', '#96BF0D', '#AB0076',];

export interface Props {
    magazine: number,
    statsFilter: StatsFilter,
    visitorsMode?: boolean,
}

export const VisitsPerAppVersionChart = ({ magazine, statsFilter, visitorsMode }: Props) => {

    return (
        <div className={classNames(styles.VisitsPerAppVersionChart, "chartContainer")}>
            <div className='chartPanel'>
                <div className='chartTitle'>{visitorsMode ? 'Visitors' : 'Visits'} by Mobile Version</div>
                <div className="separator"></div>
            </div>
            <Chart magazine={magazine} statsFilter={statsFilter} visitorsMode={visitorsMode} />
        </div>
    )
}


const Chart = ({ magazine, statsFilter, visitorsMode }: Props) => {
    const { period, audience } = statsFilter;

    const visitsPerAppVersionQuery = useQuery({
        queryKey: ['visitsPerAppVersion', magazine, period, audience, visitorsMode],
        queryFn: async () => {
            try {
                const { data } = await API.analytics.loadChartData(visitorsMode ? 'visitorsPerAppVersion' : 'visitsPerAppVersion', magazine, statsFilter);
                return data.data;
            } catch (error) {
                EdErrorHandler(error, `getting visits per app version`);
            }
        }
    });

    if (visitsPerAppVersionQuery.isFetching) {
        return <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
    }

    if (_.isEmpty(visitsPerAppVersionQuery.data) || visitsPerAppVersionQuery.data == "!") {
        return <NoDataChart />;
    }

    const data = _.orderBy(visitsPerAppVersionQuery.data, ['value'], ['desc']);

    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5 - 15;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const percentNumber = (percent * 100);
        if (percentNumber < 5) {
            return null;
        }

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${percentNumber.toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div className='chartBody'>
            <ResponsiveContainer height={280}>
                <PieChart>
                    <Pie
                        data={data}
                        nameKey="id"
                        dataKey="value"
                        label={renderCustomizedLabel}
                        innerRadius={50}
                        outerRadius={100}
                        fill="#8884d8"
                        labelLine={false}
                    >
                        {
                            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Tooltip
                        formatter={(value, name, props) => [value, `v${name}`,]}
                    />
                    <Legend
                        verticalAlign={'bottom'}
                        align="center"
                        height={50}
                        formatter={(value, entry) => `v${value}`} />

                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

const NoDataChart = () => {
    return (
        <div className={styles.NoDataChart}>No data available</div>
    )
}
