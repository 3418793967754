import { AxiosPromise } from 'axios';
import { UsersList } from '../../domain';
import { dashApi } from "../api";
import { ProfileContactInfo } from '../components/ContactInformationCard/ContactInformationCard.domain';

export const getUserList = (magazine: number, uids: string[]): AxiosPromise<UsersList> => {
  return dashApi.post(magazine, `profiles/getUsers`, { uids })
}

export const getProfileInfo = (magazine: number, uid: string): AxiosPromise<ProfileContactInfo> => {
  return dashApi.get(magazine, `profiles/getProfile?uid=${uid}`);
}