import React from 'react';
import styles from './ContactInformationCard.module.scss';
import GenericModalHeader from '../GenericModal/GenericModalHeader';
import { Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import { GenericModal } from '../GenericModal/GenericModal';
import { useQuery } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import { API } from '../../apis';
import GenericModalCloseButton from '../GenericModal/GenericModalCloseButton';
import { ProfileContactInfo } from './ContactInformationCard.domain';
import { MediaHelper } from '../../utils';
import _ from 'lodash';
import TippyReact from '../TippyReact';

interface Props {
  magazine: number,
  uid: string,
  onClose: () => void,
}

export const ContactInformationCard = ({ magazine, uid, onClose }: Props) => {

  const getProfileInfoQuery = useQuery({
    queryKey: ['getProfileInfo', uid],
    queryFn: async () => {
      try {
        const { data } = await API.profiles.getProfileInfo(magazine, uid);
        return data;
      } catch (error) {
        onClose();
        EdErrorHandler(error, `getting profile of ${uid}`);
      }
    }
  });

  const loading = getProfileInfoQuery.isFetching //|| true;

  const infoData = getProfileInfoQuery.data;

  return (
    <GenericModal isOpen={true} centered size={'lg'}>
      <div className={styles.ContactInformationCard}>
        {loading ? <OrtecLoader size={'medium'} /> :

          <>
            <div className={styles.headerTopBackground}></div>
            <ModalHeader
              toggle={onClose}
              close={<GenericModalCloseButton onClose={onClose} />}
            >
              <div className={styles.headerContainer}>
                {infoData &&
                  <>
                    <AvatarContainer info={infoData} />
                    <MainInfoContainer info={infoData} />
                  </>
                }
              </div>
            </ModalHeader>
            <ModalBody style={{ minHeight: '225px' }} >
              {infoData &&
                <>
                  <PropertyElement info={infoData} label={`company`} path={`company`} />
                  <PropertyElement info={infoData} label={`department`} path={`department`} skipIfEmpty />
                  <PropertyElement info={infoData} label={`job`} path={`jobTitle`} />
                  <PropertyElement info={infoData} label={`office`} path={`office`} skipIfEmpty />
                  <PropertyElement info={infoData} label={`email`} path={`email.work`} />
                  <PropertyElement info={infoData} label={`telephone`} path={`phone.work`} />
                  <PropertyElement info={infoData} label={`mobile phone`} path={`phone.mobile`} skipIfEmpty />
                </>
              }
            </ModalBody>
          </>
        }
      </div>
    </GenericModal >
  )
}

interface InfoProps {
  info: ProfileContactInfo
}

const MainInfoContainer = ({ info }: InfoProps) => {
  return (
    <div className={styles.MainInfoContainer}>
      <NameElement info={info} />
      <ByLineElement info={info} />
      <ActionButtonsContainer info={info} />
    </div>
  )

}

const NameElement = ({ info }: InfoProps) => {
  const firstName = getInfoProperty(info, 'firstName');
  const lastName = getInfoProperty(info, 'lastName');

  const fullName = !firstName && !lastName ? info.uid : `${firstName} ${lastName}`;

  return (
    <div className={styles.NameElement}>
      {fullName}
    </div>
  )
}
const ByLineElement = ({ info }: InfoProps) => {
  const jobTitle = getInfoProperty(info, 'jobTitle');
  const func = getInfoProperty(info, 'function');

  const byLine = jobTitle || func || '';

  if (!byLine) {
    return null;
  }

  return (
    <div className={styles.ByLineElement}>
      {byLine}
    </div>
  )
}

const ActionButtonsContainer = ({ info }: InfoProps) => {

  return (
    <div className={styles.ActionButtonsContainer}>
      <ActionButtonPhone info={info} />
      <ActionButtonEmail info={info} />
    </div>
  )
}

const ActionButtonPhone = ({ info }: InfoProps) => {
  const work = getInfoProperty(info, 'phone.work');
  const mobile = getInfoProperty(info, 'phone.mobile');

  const phone = work || mobile || '';

  if (!phone) {
    return null;
  }

  const makeCallTo = (phone: string) => {
    window.location.href = `tel:${phone}`;
  }

  return (
    <TippyReact content={`Make a phone call`}>
      <div className={styles.actionButtonElement} onClick={() => makeCallTo(phone)}>
        <i className={'fa fa-phone'}></i>
      </div>
    </TippyReact>
  )
}

const ActionButtonEmail = ({ info }: InfoProps) => {
  const email = getInfoProperty(info, 'email.work');

  if (!email) {
    return null;
  }

  const sendMailTo = (email: string) => {
    window.location.href = `mailto:${email}`;
  }

  return (
    <TippyReact content={`Send email`}>
      <div className={styles.actionButtonElement} onClick={() => sendMailTo(email)}>
        <i className={'fa fa-envelope'}></i>
      </div>
    </TippyReact>
  )
}

const AvatarContainer = ({ info }: InfoProps) => {
  const profileImageUrl = getInfoProperty(info, 'profileImageUrl');
  const profileImage = profileImageUrl ? MediaHelper.proxify(info.magazine, profileImageUrl, 160, 160) : `/assets/icons/avatarFallback.svg`;
  return (
    <div className={styles.AvatarContainer}>
      <img className={styles.avatarElement} src={profileImage} />
    </div>
  )

}

interface PropertyElementProps {
  info: ProfileContactInfo,
  label: string,
  path: string,
  skipIfEmpty?: boolean
}

const PropertyElement = ({ info, label, path, skipIfEmpty }: PropertyElementProps) => {
  const propertyText = getInfoProperty(info, path);
  if (!propertyText && skipIfEmpty) {
    return null;
  }
  return (
    <div className={styles.PropertyElement}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{propertyText || <i>Not available</i>}</div>
    </div>
  )
}



// ─── Helper Functions ────────────────────────────────────────────────────────


const getInfoProperty = (info: ProfileContactInfo, propertyPath: string): string => {
  return _.get(info.preferences, propertyPath) || _.get(info.attributes, propertyPath) || '';
}