import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import { DailyDatePicker } from '../../../../components';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { DataTableHelper, DateHelper, HistoryHelper } from '../../../../utils';
import { EdErrorHandler, OrtecLoader, SwalDelete, SwalSuccess, ToggleButton } from '../../../../widgets';
import { CommentDetails } from './CommentDetails';
import styles from './Comments.module.scss';
import { Comment } from './domain';
import * as Api from '../../../../api';
import { Channel, ChannelsSelect } from '../../../../components/ChannelsSelect/ChannelsSelect';
import { Source, SourcesSelect } from '../../../../components/SourcesSelect/SourcesSelect';
import { ArticleHelper } from '../../../../utils';
import { ContactInformationCard } from '../../../../components/ContactInformationCard/ContactInformationCard';


const DEFAULT_INIT_DATE_RANGE: number = 360 //IN DAYS;

interface Props {
  magazine: number,
  profile: Profile
}

export const Comments = ({ magazine, profile }: Props) => {

  const [comments, setComments] = useState<Comment[]>([]);

  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [filtersEnabled, setFiltersEnabled] = useState<boolean>(false);

  const [fromDate, setFromDate] = useState<string>(moment().subtract(profile.edConfig?.commentsInitRange || DEFAULT_INIT_DATE_RANGE, 'days').format('YYYY-MM-DD'));
  const [untilDate, setUntilDate] = useState<string>(DateHelper.todayAsString());

  const [selected, setSelected] = useState<string[]>([]);

  const [channels, setChannels] = useState<Channel[]>([]);
  const [sources, setSources] = useState<Source[]>([]);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [loadingSources, setLoadingSources] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<string>('');
  const [selectedSource, setSelectedSource] = useState<string>('');

  const [contactCardUid, setContactCardUid] = useState<string>();


  const loading = dataLoading || loadingChannels || loadingSources || deleteLoading;

  const history = useHistory();

  const viewArticle = HistoryHelper.getQueryParamNumber(history, 'article');
  const viewComment = HistoryHelper.getQueryParam(history, 'comment');

  const MAXIMUM_DATE_RANGE = profile.edConfig?.commentsMaxRange;


  useEffect(() => {
    loadChannels();
    loadSources();
  }, []);

  useEffect(() => {
    loadComments(fromDate, untilDate, selectedChannel, selectedSource);
  }, [fromDate, untilDate, selectedChannel, selectedSource])

  const loadChannels = async () => {
    try {
      setLoadingChannels(true);
      const { data } = await Api.getMagazineChannels(magazine);
      setChannels(data.channels);
    } catch (error) {
      EdErrorHandler(error, 'getting magazine channels');
      setChannels([])
    } finally {
      setLoadingChannels(false);
    }
  }

  const loadSources = async () => {
    try {
      setLoadingSources(true);
      const { data: sources } = await Api.getSources(magazine);
      setSources(sources);
    } catch (error) {
      EdErrorHandler(error, 'getting magazine sources');
      setSources([])
    } finally {
      setLoadingSources(false);
    }
  }


  const loadComments = async (from: string, until: string, channel: string, source: string) => {
    try {
      setDataLoading(true);
      const { data } = await API.comments.getCommentsWithinDates(magazine, from, until, channel || undefined, source || undefined);
      setComments(_.filter(data, (c) => c.status == 1));
    } catch (error) {
      EdErrorHandler(error, 'getting magazine comments');
      setComments([])
    } finally {
      setDataLoading(false);
    }
  }

  const onToggleFilters = (enabled: boolean) => {
    if (!enabled) {
      setSelectedChannel('');
      setSelectedSource('');
    }
    setFiltersEnabled(enabled);
  }

  const onCommentDetailsOpen = (c: Comment) => {
    HistoryHelper.setQueryParams(history, { article: c.articleId, comment: c.id });
  }

  const onCommentDetailsClose = () => {
    HistoryHelper.setQueryParams(history, { article: undefined, comment: undefined });
    loadComments(fromDate, untilDate, selectedChannel, selectedSource);
  }

  const onDeleteComment = async (comment: Comment) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Yes, delete it!`,
      focusCancel: true,
      html: `This action will permanently delete the comment${comment.replies == '0' ? '' : ` along with its ${comment.replies} ${comment.replies == '1' ? 'reply' : 'replies'}`}.`
    });
    if (!confirm) {
      return;
    }
    await deleteComments([comment.id]);
    loadComments(fromDate, untilDate, selectedChannel, selectedSource);
  }

  const onDeleteSelectedComments = async () => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Yes, delete it!`,
      focusCancel: true,
      html: `This action will permanently delete all ${selected.length} selected comments along with their replies.`
    });
    if (!confirm) {
      return;
    }
    console.log(selected);

    await deleteComments(selected);
    loadComments(fromDate, untilDate, selectedChannel, selectedSource);
  }

  const deleteComments = async (commentIds: string[]) => {
    if (commentIds.length == 0) {
      return;
    }
    try {
      setDeleteLoading(true);
      await API.comments.deleteComments(magazine, commentIds);
      SwalSuccess.fire({
        title: 'Success!',
        text: commentIds.length == 1 ? `Comment has been deleted successfully!` : `All ${commentIds.length} comments have been deleted successfully!`,
        customClass: {
          popup: 'noBounce'
        },
        showConfirmButton: false,
        timer: 1000,
      });
      setSelected([]);
    } catch (error) {
      EdErrorHandler(error, `deleting comments.`)
    } finally {
      setDeleteLoading(false);
    }
  }

  const onChangeFromDate = (d: string) => {
    setFromDate(d);
    if (MAXIMUM_DATE_RANGE && moment(d) < moment(untilDate).subtract(MAXIMUM_DATE_RANGE, 'days')) {
      setUntilDate(moment(d).add(MAXIMUM_DATE_RANGE, 'days').format('YYYY-MM-DD'));
    }
  }

  const onChangeUntilDate = (d: string) => {
    setUntilDate(d);
    if (MAXIMUM_DATE_RANGE && moment(d) > moment(fromDate).add(MAXIMUM_DATE_RANGE, 'days')) {
      setFromDate(moment(d).subtract(MAXIMUM_DATE_RANGE, 'days').format('YYYY-MM-DD'));
    }
  }

  const openContactCard = (uid: string) => {
    setContactCardUid(uid);
  }

  const closeContactCard = () => {
    setContactCardUid(undefined);
  }

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'text',
      text: 'Comment / article title (by author)',
      formatter: (cell: string, row: Comment) => {
        return (
          <div>
            <div className={styles.commentText}>
              {row.text}
            </div>
            <div className={styles.articleTitle}>
              {ArticleHelper.convertToReadableTitle(_.trim(row.articleVariationTitle, '"') || row.articleTitle || row.articleId)}
              {row.articleAuthorName && <span className={styles.articleAuthor}>by {row.articleAuthorName}</span>}
            </div>
          </div>
        )
      }
    },
    {
      dataField: 'replies',
      text: 'Replies',
      sort: true,
      headerStyle: { width: '100px' },
      formatter: (cell: string, row: Comment) => {
        if (cell == '0') {
          return row.parentId ? <img src={'/assets/icons/16/child_arrow.svg'} /> : ''
        }

        return cell;
      }

    },
    {
      dataField: 'authorName',
      text: 'User',
      sort: true,
      headerStyle: { width: '200px' },
      formatter: (authorName: string, row: Comment) => {
        if (!row.authorUid) {
          return authorName;
        }
        return (
          <span className={styles.linkText} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openContactCard(row.authorUid);
            return false;
          }}>
            {authorName}
          </span>
        )
      }
    },
    {
      dataField: 'cdate',
      text: 'Date',
      sort: true,
      formatter: DataTableHelper.dateFormatter,
      headerStyle: { width: '200px' }
    },
    {
      dataField: 'text',
      hidden: true,
      text: 'text'
    },
    {
      dataField: 'articleTitle',
      hidden: true,
      text: 'article title'
    },
    {
      dataField: 'articleVariationTitle',
      hidden: true,
      text: 'article variation title'
    },
    {
      dataField: 'articleId',
      hidden: true,
      text: 'article ID'
    },
    {
      dataField: 'articleAuthorName',
      hidden: true,
      text: 'author'
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: Comment) => {
        return (
          <div className={'actionHoveringDiv'}>
            <Button color={'secondary'} onClick={(e) => { onDeleteComment(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      }
    }
  ];

  const selectRow: SelectRowProps<Comment> = {
    mode: 'checkbox',
    clickToSelect: false,
    headerColumnStyle: { width: '42px' },
    selected: [...selected],
    onSelect: (row, isSelect, rowIndex, e) => {
      const newSelected = isSelect ? [...selected, row.id] : _.filter(selected, (s) => s !== row.id);
      setSelected(newSelected);
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelected(isSelect ? [..._.map(rows, 'id')] : [])
    }
  }

  const MAX_PERIOD_RANGE_TEXT: React.ReactNode = MAXIMUM_DATE_RANGE ? <div className={styles.maxPeriodRangeText}>Max period range: {MAXIMUM_DATE_RANGE} days.</div> : null;

  return (
    <div className={styles.Comments}>
      {loading && <OrtecLoader />}
      <DataTable
        data={comments}
        columns={columns}
        defaultSorted={[{ dataField: 'cdate', order: 'desc' }]}
        keyField={'id'}
        selectRow={selectRow}
        noDataText={`No comments found with current filters! Please select a different date range and/or different channel and source.`}
        // defaultSizePerPage={25}
        onlySearchBar
        searchFilters={
          <div className={styles.dateFiltersContainer}>
            <DailyDatePicker
              period={fromDate}
              changePeriod={onChangeFromDate}
              maxPeriod={untilDate}
              popperCustomClassName={styles.datePickerPopper}
              dayClassName={(d: Date) => {
                if (!MAXIMUM_DATE_RANGE) {
                  return null;
                }
                return d < moment(untilDate).subtract(MAXIMUM_DATE_RANGE, 'days').toDate() ? styles.datePickerOffRange : null;
              }}
              notClearable
            >
              {MAX_PERIOD_RANGE_TEXT}
            </DailyDatePicker>
            <DailyDatePicker
              period={untilDate}
              changePeriod={onChangeUntilDate}
              minPeriod={fromDate}
              maxPeriod={DateHelper.todayAsString()}
              popperCustomClassName={styles.datePickerPopper}
              dayClassName={(d: Date) => {
                if (!MAXIMUM_DATE_RANGE) {
                  return null;
                }
                return d < moment().toDate() && d > moment(fromDate).add(MAXIMUM_DATE_RANGE, 'days').toDate() ? styles.datePickerOffRange : null;
              }}
              notClearable
            >
              {MAX_PERIOD_RANGE_TEXT}
            </DailyDatePicker>
          </div>
        }
        secondaryElements={
          <ToggleButton onClick={() => { onToggleFilters(!filtersEnabled) }} color={''} active={filtersEnabled} style={{ marginLeft: 'unset' }}>
            <div>
              {filtersEnabled ? `Clear` : `Apply`} Filters  <img style={{ marginLeft: "5px", verticalAlign: "text-bottom" }} src={`/assets/icons/16/advanced_${filtersEnabled ? 'grey' : 'blue'}.svg`} />
            </div>
          </ToggleButton>
        }
        secondaryRow={
          !filtersEnabled ? undefined :
            <div className={styles.filtersContainer}>
              <Row>
                <Col md={6}>
                  <Label>Channel:</Label>
                  <ChannelsSelect
                    channels={channels}
                    selected={''}
                    onChange={(id: string) => {
                      setSelectedChannel(id);
                      setSelectedSource('');
                    }}
                    channelGraphName={profile.edConfig?.channelGraphName}
                  />
                </Col>
                <Col md={6}>
                  <Label>Source:</Label>
                  <SourcesSelect
                    key={selectedSource}
                    sources={sourcesInChannel(sources, selectedChannel)}
                    selected={selectedSource}
                    onChange={(id: string) => {
                      setSelectedSource(id);
                    }}
                  />
                </Col>
              </Row>
            </div>
        }
        searchbarPlacholder={`Search for comments, authors and article titles or ID`}
        onRowClick={(row: Comment) => { onCommentDetailsOpen(row) }}
        rowClasses={(row, rowIndex) => { return styles.overviewRow }}
      />
      {contactCardUid &&
        <ContactInformationCard
          magazine={magazine}
          uid={contactCardUid}
          onClose={closeContactCard}
        />
      }
      {!viewArticle && selected.length > 1 &&
        <div className={styles.deleteButtonWrapper}><Button onClick={onDeleteSelectedComments}>Delete selected comments</Button></div>
      }
      {viewArticle && <CommentDetails magazine={magazine} profile={profile} onClose={onCommentDetailsClose} articleId={viewArticle} activeCommentId={viewComment} />}
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const sourcesInChannel = (sources: Source[], channelId: string): Source[] => {
  if (!channelId) {
    return [...sources];
  }
  return _.filter(sources, (s) => {
    return _.includes(s.parentChannels, _.toNumber(channelId));
  })
}