import React, { useState } from 'react';
import { FlaggedContent } from './ReportedContentDomain';

import styles from './ReportedContentReports.module.scss'
import _ from 'lodash';
import { DateHelper, JsonHelper } from '../../../../utils';
import { Badge, Button, ModalBody } from 'reactstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import { Comment } from '../comments/domain';
import { ContactInformationCard } from '../../../../components/ContactInformationCard/ContactInformationCard';

interface Props {
  magazine: number,
  reports: FlaggedContent[],
  compareWithDate?: string
  reportedComment?: Comment
}

export const ReportedContentReports = ({ magazine, reports, compareWithDate, reportedComment }: Props) => {

  return (
    <div className={styles.ReportedContentReports}>
      {
        _.map(reports, (r) => {
          return <ReportItem key={r.id} magazine={magazine} report={r} compareWithDate={compareWithDate} reportedComment={reportedComment} />
        })
      }
    </div>
  )
}

// ─── Report Item ─────────────────────────────────────────────────────────────

interface ReportItemProps {
  magazine: number,
  report: FlaggedContent,
  compareWithDate?: string,
  reportedComment?: Comment

}

export const ReportItem = ({ magazine, report, compareWithDate, reportedComment }: ReportItemProps) => {

  const isReviewed = report.status !== 'new';

  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [showOriginalComment, setShowOriginalComment] = useState<boolean>(false);

  const [contactCardUid, setContactCardUid] = useState<string>();

  const openContactCard = (uid: string) => {
    setContactCardUid(uid);
  }

  const closeContactCard = () => {
    setContactCardUid(undefined);
  }


  const queryClient = useQueryClient();


  const markAsReviewedMutation = useMutation({
    mutationKey: ['reviewReport', magazine, report.id],
    mutationFn: (reportId: number) => API.reportedContent.markReportAsReviewed(magazine, reportId),
    onMutate: () => {
      setIsMutating(true);
    },
    onError: (error) => {
      EdErrorHandler(error, `marking report as reviewed`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['reportedContent', magazine, report.articleId] })
    },
    onSettled: () => {
      setIsMutating(false);
      queryClient.invalidateQueries(['reportedContents', magazine]);
    }
  });

  const onMarkAsReviewed = () => {
    markAsReviewedMutation.mutate(report.id);
  }

  const onOpenOriginalComment = () => {
    setShowOriginalComment(true);
  }

  const onCloseOriginalComment = () => {
    setShowOriginalComment(false);
  }

  const getReportedContentText = () => {

    try {

      const meta = JsonHelper.jsonParse(report.meta) as { reportedContent: string };
      if (!meta?.reportedContent) {
        return '';
      }
      return meta.reportedContent;
    } catch (error) {
      return ``
    }
  }

  // const isLoading = markAsReviewedMutation.isLoading || (markAsReviewedMutation.isSuccess && queryClient.isFetching({ queryKey: ['reportedContent', magazine, report.articleId] }));

  return (
    <>
      <div className={styles.ReportItem}>
        <div className={styles.reportContent}>
          <div className={styles.reportText}>{report.report}</div>
          <div className={styles.reportInfo}>
            <div className={styles.reportAuthor}>
              <span className={styles.linkText} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openContactCard(report.uid);
                return false;
              }}>
                {report.uid}
              </span>
            </div>
            <div className={styles.reportDate}>{DateHelper.dateTimeToLocaleString(report.mdate)}</div>
            {compareWithDate && new Date(compareWithDate) > new Date(report.mdate) ?
              (report.contentType === 'comment' ?
                <span className={styles.viewOriginalCommentText} onClick={onOpenOriginalComment}>View original comment</span>
                :
                <span className={styles.reportedBeforeUpdateText}>Reported before update</span>
              )
              : null
            }
          </div>
        </div>
        <div className={styles.reportAction}>
          {isMutating ?
            <OrtecLoader size={'icon'} />
            :
            isReviewed ?
              <Badge pill>
                reviewed
              </Badge>
              :
              <Button color={'secondary'} onClick={onMarkAsReviewed}>Mark as reviewed</Button>

          }
        </div>
      </div>
      {showOriginalComment &&
        <GenericModal isOpen={true}>
          <GenericModalHeader title='Original Comment' onClose={onCloseOriginalComment} />
          <ModalBody>
            <div className={styles.originalCommentText}>{getReportedContentText()}</div>
            <div className={styles.reportInfo}>
              <div className={styles.reportAuthor}>{reportedComment?.authorName}</div>
              <div>Before {DateHelper.dateTimeToLocaleString(report.mdate)}</div>
            </div>
          </ModalBody>
        </GenericModal>
      }
      {contactCardUid &&
        <ContactInformationCard
          magazine={magazine}
          uid={contactCardUid}
          onClose={closeContactCard}
        />
      }
    </>
  )
}